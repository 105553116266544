import React from "react";
import configData from '../../config/config.json';
import './index.css';
import Modal from 'react-responsive-modal';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Cookies from 'universal-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Slide from '@material-ui/core/Slide';

//Set the styles
const Dialog = withStyles(() => ({
    paper: { 
        minWidth: "350px",
        padding: '.5em' },
  }))(MuiDialog);
  
const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
      textAlign: 'right'
    },
}))(MuiDialogContent);

const DialogTitle = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: 0,
      textAlign: 'right'


    },
}))(MuiDialogTitle);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const cookies = new Cookies();
class Promotion extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            open_promotion: this.props?.show?true:false
        }

        //binding
        this.showPromotionModal = this.showPromotionModal.bind(this);
    }

    showPromotionModal = (event, open) => {
        console.log(event)
        console.log(open)
        if (event)
            event.stopPropagation()

        //console.log(open)
        this.setState({ 
            open_promotion: open,
            open: false
         });

         if  (open==false) {
            cookies.set('hide_hp_coupon_popup',true,{ 
                path: '/' ,
                maxAge: 1 // 10 = 10 sec, 4 hour = 60*60*4
              })
         }
    };

    render() {
        let content = (<></>);

        if (this.props?.campaign==='spa' && window.location.pathname=='/') {
            content = (
                <Dialog
                    open={this.state.open_promotion && !cookies.get('hide_hp_coupon_popup')} 
                    onClose={(e) => this.showPromotionModal(e,false)}
                    direction="rtl"
                    minWidth="xl"
                    style={{minWidth:'325px'}}
                    scroll="paper"
                    TransitionComponent={Transition}
                    sx={{
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                          },
                        },
                      }}
                    >
                    <DialogTitle>
                        <button tabIndex={0}
                         style={{border:'none', background:'none'}} 
                         title="הסתר פרסומת"
                         onClick={e=>{this.showPromotionModal(e, false)}} >
                        <FontAwesomeIcon icon={faTimes} size="1x" style={{color:'#000', cursor:'pointer'}}/>
                    </button>
                    </DialogTitle>
                    <DialogContent
                        style={{padding:'0 1.25em 1.25em 1.25em'}}
                    >
                    
                        <div dir="rtl" id="modal_content" style={{color:'#000', fontWeight:'600', display:'none', padding:'1em'}} tabIndex={0}>
                            <p><h4><strong>מזמינים חופשה בעפנו ומקבלים יום ספא זוגי ב'חמי געש' במתנה!</strong></h4>
                            </p>

                            <p>יום ספא זוגי במרחצאות 'חמי געש' (כ- 12 דקות נסיעה מת"א!) כולל שימוש חופשי במתקני הספא הכוללים - 4 בריכות תרמו-מינרליים, ממעיינות טבעיים עם 'דושים' של עיסוי, סאונות יבשות ורטובות, בריכה שחייה ומתחם מנוחה</p>
                            <p><h5><strong>אופן המימוש</strong></h5>
                            למימוש ההטבה,  יש לשלוח הודעת וואטסאפ עם הכיתוב  "חופשה וספא" למספר 050-7801801 ולציין את מספר ההזמנה (חובה ליצור קשר תוך כ- 7 ימים מיום ביצוע ההזמנה - לכל המאוחר!)</p>
                            <p>
                            <h5><strong>תנאי ההטבה</strong></h5>
                            ההטבה כוללת כפל מבצעים והטבות - ההטבה אינה תקפה להזמנות המבוצעות באמצעות קוד קופון/שובר זיכוי (מכל סוג). ההטבה תקפה להזמנות חדשות שיבוצעו באתר עפנו עד ה 30.11.24 או עד גמר המלאי, המוקדם מבניהם. החברה רשאית לסיים/לשנות את מתן ותנאי ההטבה בכל עת ללא הודעה מוקדמת. שובר הספא יהיה בתוקף מינימלי ל- 11 חודשיים (לפחות).  מימוש יום הספא כפוף לתיאום מראש מול נותן השירות ולתנאי שובר הספא המלאים המפורטים ע"ג השובר. תיתכן הגבלת שימוש/תוספת תשלום בסופ"ש, חגים ומועדים. מסוכם מראש שבמידה והזמנת החופשה תבוטל (מכל סיבה) הלקוח ישתתף עם החברה בעלות מסוכמת מראש של עלות השוברים בסך של - 100₪ לנוסע (בין אם מימש או לא מימש את השוברים). ההטבה תקפה לשימוש עבור מבוגרים בלבד (לדוגמא - בהזמנה חופשה משפחתית של זוג מבוגרים ו- 2 ילדים תהיו זכאים ל 2 שוברי ספא בלבד). בהזמנת חופשה ליחיד תהיו זכאים לשובר 1 (יחיד) לספא. יתכן שחלק ממתקני הספא יהיו סגורים לשימוש (יש להתעדכן מול נותן השירות אודות המתקנים). יתכנו תוספות/שדרוגים לבחירתכם ישירות מול נותן השירות. ט.ל.ח
                            </p>
                            <center><button tabIndex={0} title="הבנתי" onClick={(e) => this.showPromotionModal(e,false)}>הבנתי</button></center>

                        </div>
                    
                        <a href="#" title="מבצע בלאק פריידי: מזמינים חופשה ומקבלים יום ספא זוגי במתנה. כולל כפל מבצעים והטבות. לחצו כאן המלאי מוגבל * בכבוף לתנאי ההטבה המלאים המפורטים בדף ההטבה" id="popup_img" onClick={(e)=> {
                                window.document.getElementById('popup_img').style.display = 'none'
                                window.document.getElementById('modal_content').style.display = 'inherit'
                            }} >
                                <img 
                                    tabIndex={0}
                                    src="/images/promotions/spa/image.jpg" 
                                    alt='מבצע בלאק פריידי: מזמינים חופשה ומקבלים יום ספא זוגי במתנה. כולל כפל מבצעים והטבות. לחצו כאן המלאי מוגבל * בכבוף לתנאי ההטבה המלאים המפורטים בדף ההטבה' 
                                    style={{border:'2px solid #000'}} 
                                    border="0" />
                            </a>
                    </DialogContent>
                </Dialog>

                            
            )
        }

        if (this.props?.campaign==='coupon-50') {
            content = (
                
                <Modal 
                    open={this.state.open_promotion && !cookies.get('hide_hp_coupon_popup')} onClose={(e) => this.showPromotionModal(e,false)}
                    center 
                    blockScroll={true}
                    closeIcon={<><FontAwesomeIcon icon={faTimes} size="1x" style={{color:'#000', cursor:'pointer'}}/></>}
                    animationDuration={1000}
                    style={{maxWidth:'300px', padding:0, flexDirection:'reverse'}} 
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    closeAfterTransition
                    >
                    <a href="https://wa.link/vzatw1">
                        <img src="/images/promotions/coupon-50/image.jpg" alt='מבצע סופש מטורף' style={{width:'100%',border:'none', marginTop:'1.5em'}} border="0"></img>
                    </a>
                </Modal>
                            
            )
        }

        if (this.props?.campaign==='crazyweekend') {
            content = (
                <div className={this.props?.hide?'hidden':'PromotionBox'}  onClick={(e) => this.showPromotionModal(e, true)}>
                    <div className="subtitle_item" >
                        <div className="icons_wrapper" style={{maxHeight:'unset', padding:0, background:'inherit'}}>
                        <div className="icons_content" style={{}}>
                            <img src="/images/promotions/crazyweekend/card.png" style={{width:'85px'}} alt='מבצע סופש מטורף'></img>
                            <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                                <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                                    <p><br /><strong>סוף שבוע מטורף בעפנו!</strong><br /><br />
                                    חם חם חם לנו! אז זה בדיוק הרגע לעוף לנופש בחו"ל ולקפוץ הישר לבריכה!
                                    </p>

                                    <p>מזמינים חופשה לקיץ במהלך סוף השבוע, מזינים את הקופון CRAZY40 ונהנים ממתנה מיידית של $40 הנחה בחיוב.</p>
                                    <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                                    <p>שימו לב: המבצע תקף להזמנות לזוג נוסעים או יותר בערך של לפחות $350 לנוסע, שיבוצעו במהלך סוף השבוע בלבד</p>

                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
        
        if (this.props?.campaign==='weekend30') {
            content= (
                <div className={this.props?.hide?'hidden':'PromotionBox'}  onClick={(e) => this.showPromotionModal(e, true)}>
                    <div className="subtitle_item" >
                        <div className="icons_wrapper" style={{maxHeight:'unset', padding:0, background:'inherit'}}>
                        <div className="icons_content" style={{}}>
                            <img src="/images/promotions/weekend30/card.png" style={{width:'85px'}} alt='מבצע סופש מטורף'></img>
                            <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                                <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                                    <p><br /><strong>סוף שבוע מטורף בעפנו!</strong><br /><br />
                                    חם חם חם לנו! אז זה בדיוק הרגע לעוף ולהזמין חופשה לחול במחיר זול עוד יותר !
                                    </p>

                                    <p>מזמינים חופשה במלונות הכל-כלול במהלך סוף השבוע, מזינים את הקופון CRAZY30 ונהנים ממתנה מיידית של $30 הנחה בחיוב.</p>
                                    <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                                    <p>שימו לב: המבצע תקף להזמנות לזוג נוסעים או יותר בערך של לפחות $250 לנוסע, שיבוצעו במהלך סוף השבוע בלבד. ללא כפל מבצעים</p>

                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            )
        } 
        
        if (this.props?.campaign==='rosh25') {
            content= (
                <div className={this.props?.hide?'hidden':'PromotionBox'}  onClick={(e) => this.showPromotionModal(e, true)}>
                    <div className="subtitle_item" >
                        <div className="icons_wrapper" style={{maxHeight:'unset', padding:0, background:'inherit'}}>
                        <div className="icons_content" style={{}}>
                            <img src="/images/promotions/rosh25/card.png" style={{width:'85px'}} alt='מבצע ראש השנה'></img>
                            <Modal open={this.state.open_promotion} onClose={(e) => this.showPromotionModal(e,false)} center >
                                <div dir="rtl"  className="modal_content" style={{color:'#000', fontWeight:'600'}}>
                                    <p><br /><strong>ראש השנה מטורף בעפנו!</strong><br />
                                    </p>

                                    <p>מזמינים חופשה במהלך החג, מזינים את הקופון ROSH25 ונהנים ממתנה מיידית של $25.</p>
                                    <p>חשוב שתדעו - את קוד הקופון יש להזין בשלב התשלום (לפני הזנת פרטי כרטיס האשראי)</p>
                                    <p>שימו לב: המבצע תקף להזמנות בערך של לפחות $200 לנוסע, שיבוצעו במהלך החג בלבד. ללא כפל מבצעים</p>

                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            )
        }

        return content;
        
        
    }

}
export default Promotion