import React, {Component}  from "react";
import ReactDOM from 'react-dom';

import configData from '../../config/config.json';

// gui elements
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
//import MuiDialogActions from '@material-ui/core/DialogActions';

// snackbar + alert
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// close button
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './index.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Import calendar Hebrew
import {registerLocale} from "react-datepicker";
import he from './date-fns/locale/he';
registerLocale('he', he)

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);
  
// NUMBER OF MONTHS TO SHOW AHEAD
const startMonth = configData.CALENDAR?.start_month;
const endMonth   = configData.CALENDAR?.end_month;
const skipMonthsArray = configData?.CALENDAR?.skip_months; // date in N months from today (last day of that month)      

// Calculate the start date either by using the provided start date or by subtracting the months count from the endDate
let dt = new Date();
let minDate = new Date(dt.getTime() + 2 * 24 * 60 * 60 * 1000); // date in 2 days time

if (startMonth && dt.getTime() <=new Date(startMonth.split('-')[0],startMonth.split('-')[1]-1,1).getTime()) {
    minDate = new Date(startMonth.split('-')[0],startMonth.split('-')[1]-1,1);
}

// Calculate the end date either by using the provided end date or by adding the months count to the startDate
const endDate = new Date(endMonth.split('-')[0],endMonth.split('-')[1],1); // :new Date(dt.setMonth(currentMonth+nMonths));

let maxDate = new Date(endDate.getFullYear(), endDate.getMonth() , 0); // 0=set date to the last day of month in nMonths months (7)

// Calculate the number of months we'll show
const nMonths = Math.round((endDate.getTime() - minDate.getTime())  / (1000 * 60 * 60 * 24 * 30));

class Calendar extends Component {
        
    constructor (props) {
        super(props)

        // init
        this.MIN_NIGHTS_COUNT = 3;
        this.MAX_NIGHTS_COUNT = 60

        //if (!this.props.location || !this.props.location.search || this.props?.location?.search==="") {
            //console.log('default')
            this.state = {
                range: this.props?.range,
                date_picker: this.props?.open,
                window: {
                    dates_not_allowed: false,
                    dates_max_nights_error: false,
                    dates_min_nights_error: false
                }
            }
        //}       

        // binding
        this.toggleCalendarRange = this.toggleCalendarRange.bind(this); 
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this); 


        
    }

    
    componentDidMount() {
        // Monitor scrolling to show filters after 3rd result
        document.addEventListener('scroll', this.handleScroll);


        
    }

    closePicker = () => {
        this.props.onCloseCalendar()
    }


    /**
     * Triggered when from or to date is clicked in the calendar object
     * @param {*} dates 
     */

    handleDatesSelection = (dates) => {
        console.log('HANDLEDATE')
        const from = (dates[0])
        const to = (dates[1])

        if (from && !to) {
            var el = document.createElement("div");
            var id = "speak-" + Date.now();
            el.setAttribute("id", id);
            el.setAttribute("aria-live",  "assertive");
            el.setAttribute("tab-index",  0);
            
            el.classList.add("visually-hidden");
            document.body.appendChild(el);

            window.setTimeout(function () {
                document.getElementById(id).innerHTML = 'בחרת את תאריך היציאה, כעת עליך לבחור את תאריך החזרה';
            }, 100);

            window.setTimeout(function () {
                document.body.removeChild(document.getElementById(id));
            }, 1000);
        }                      

        

        if (!from && !to) return false

        let nightsCount = -1;

        if (dates[0] && dates[1])
            nightsCount = Math.ceil((to.getTime() - from.getTime()) / (1000*3600*24));

        if (nightsCount >=0 && nightsCount< this.MIN_NIGHTS_COUNT) {
            this.setState(prevState=>({
                ...prevState,
                window: {
                    ...prevState.window,
                    dates_min_nights_error: true,
                    dates_min_nights_allowed: this.MIN_NIGHTS_COUNT
                }
            }))
            
            return false;
        }

        if (nightsCount > this.MAX_NIGHTS_COUNT) {
            this.setState(prevState=>({
                ...prevState,
                window: {
                    ...prevState.window,
                    dates_max_nights_error: true,
                    dates_max_nights_allowed: this.MAX_NIGHTS_COUNT
                }
            }))
            
            return false;
        }

        // check if there are any months to skip
        if (skipMonthsArray && skipMonthsArray.length > 0) {
            for (let i=0;i<skipMonthsArray.length;i++) {

                const skipDate =  skipMonthsArray[i].split('-');
                const yearToSkip = skipDate[0]
                const monthToSkip = skipDate[1]-1;

                const firstDayOfMonth = new Date(yearToSkip,monthToSkip,1);
                const lastDayOfMonth = new Date(firstDayOfMonth.getFullYear(),firstDayOfMonth.getMonth()+1,0);
                
                if (from && to && 
                    from.getTime() >= firstDayOfMonth.getTime() && 
                    to.getTime() <= lastDayOfMonth.getTime()) {
                
                    this.setState(prevState=>({
                        ...prevState,
                        range: null,
                        window: {
                            ...prevState.window,
                            dates_not_allowed: true,
                        }
                    }));
                    
                    return false;  
                }
            }
        }

        // If nights count > 7 && return date was selected => make sure it's changed to flexible dates type
        if (nightsCount > 7 && dates[1]) {
            this.toggleCalendarRange(true)
        }

        
        this.setState((prevState)=>({
            ...prevState,
            range: dates,
            dates_type: dates[1] && nightsCount>7?'range':prevState.dates_type, 
            date_picker: !dates[1] // show date picker until target date selected
        }), ()=>{
            
            this.props.sendCalendarData(
                dates,
                this.state.dates_type
            )
        })

       

    }

    /**
     * dates_type = can be either exact or range
     */
    toggleCalendarRange = (enabled) => {
      
        this.setState((prevState) => ({
            ...prevState,
            range: null, 
            dates_type: enabled?'range':'exact',
        })
        );      

    }

    handleSnackbarClose = () => {
        this.setState(prevState=>(
            {
                ...prevState,
                window: {
                    dates_not_allowed: false,
                    dates_min_nights_error: false,
                    dates_max_nights_error: false
                }
            }
        ))
    }


    render(){

        return (
            <Dialog fullScreen
                open={this.props?.open?true:false}  
                fullScreen 
                onClose={e=>{this.closePicker()}} 
                scroll="paper"
                id="search_wizard" > 
              <AppBar style={{ position: 'relative', textAlign:'center'}}>
                  <Toolbar style={{background:'#124b71'}}>
                      <IconButton edge="start" color="inherit" onClick={e=>{this.closePicker()}} aria-label="close">
                          <CloseIcon />
                      </IconButton>
                      <Typography variant="h5" style={{flex: 1}}>
                          בחירת תאריכים
                      </Typography>
                      <Box component="span" m={2}></Box>
                  </Toolbar>

                   <Grid container spacing={2} className='calendarButtonsBar'>
                        <Grid item xs={6}>
                            <Button
                                className="calendarToggleButton"
                                variant={ (!this.state?.dates_type || this.state?.dates_type=='' || this.state?.dates_type==='exact')?'contained':'outlined'}
                                color='primary'
                                onClick={e=>this.toggleCalendarRange(false)}
                                style={{fontWeight:800}}
                            >תאריכים מדוייקים
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                className="calendarToggleButton"
                                variant={( this.state?.dates_type==='range' )?'contained':'outlined'}
                                color='primary'
                                onClick={e=>this.toggleCalendarRange(true)}
                                style={{fontWeight:800}}
                            >טווח תאריכים
                            </Button>
                        </Grid>                        
                    </Grid>
              </AppBar>
              <DialogContent >
                <div className='notification' style={{paddingTop:'1em', textAlign:'center'}}                         tabIndex={0}>
                    <WbIncandescentIcon 
                        className='icon'
                    /> {
                            this.state?.dates_type!=='exact'?
                                ('ככל שתסמנו טווח גדול יותר תמצאו דילים זולים יותר'):
                                ('חיפוש מדוייק יציג תוצאות בתאריכים הנבחרים בלבד!')
                        }
                </div>    
                
                <div tabIndex={0} style={{fontSize:0, color:'#fff'}}>שימו לב: נווטו באמצעות החצים לתאריך היציאה המבוקש ובחירו אותו על ידי לחיצה על מקש ENTER. אחר כך בחרו את תאריך החזרה ולסיום לחצו ENTER </div>


                    <Snackbar
                        tabIndex={0}
                        open={this.state.window?.dates_min_nights_error}
                        autoHideDuration={3000}
                        onClose={this.handleSnackbarClose}
                    >
                        <MuiAlert elevation={5} variant="filled" severity="error">לא ניתן לבחור פחות מ-{this.MIN_NIGHTS_COUNT} לילות</MuiAlert>
                    </Snackbar>
                    
                    <Snackbar
                        tabIndex={0}
                        open={this.state.window?.dates_max_nights_error}
                        autoHideDuration={3000}
                        onClose={this.handleSnackbarClose}
                    >
                        <MuiAlert elevation={6} variant="filled" severity="error">לא ניתן לבחור יותר מ-{this.MAX_NIGHTS_COUNT} לילות</MuiAlert>
                    </Snackbar>

                    <Snackbar
                        tabIndex={0}
                        open={this.state.window?.dates_not_allowed}
                        autoHideDuration={3000}
                        onClose={this.handleSnackbarClose}
                    >
                        <MuiAlert elevation={7} variant="filled" severity="error">באופן זמני לא ניתן לבחור תאריכים בחודש זה. נסו לבחור בחודש אחר</MuiAlert>
                    </Snackbar>

                  <div style={{direction:'rtl'}} id="calendar" >
                  <DatePicker
                    id="dp"
                    onKeyDown={e=>{
                        console.log(e.key)
                        console.log(e.keyCode)

                        if (e.key=='Enter') {
                            console.log('ENTER')
                            const elm = document.createElement('div');
                            elm.setAttribute('aria-live', "assertive")

                            const cont = document.createTextNode('יופי יופי') 
                            elm.appendChild(cont);
                            document.body.insertBefore(elm, document.getElementById('dp'))
                            elm.focus()


                        }
                        return true;
                        e.stopPropagation()
                        e.preventDefault();
                        
                        if (e.key == 'ArrowRight') {
                            new KeyboardEvent('keydown', {
                                key: 'ArrowLeft'
                            });
                            
                        }
                        if (e.key == 'ArrowLeft') {
                            console.log('left arrow pressed');
                            new KeyboardEvent('keydown', {
                                key: 'ArrowRight',
                                keyCode: 39,
                                bubbles: true,
                                cancelable: true,
                                shiftKey: false,
                                ctrlKey: false,
                                altKey: false,
                                metaKey: false,
                            });
                        }

                        return false;
                    }}
                      
                      //selected={startDate}
                      onChange={dates => {
                            this.handleDatesSelection(dates)
                        }
                      }
                      startDate={this.state?.range?this.state?.range[0]:null}
                      endDate={this.state?.range?this.state?.range[1]:null}
                      selectsRange={true}
                      dateFormat="yyyy-MM-dd"
                      minDate={minDate}
                      maxDate={maxDate} // allow selecting up to this date
                      //showTwoColumnMonthYearPicker={true}
                      //dayClassName="dayClass"
                      //onChange={(res)=>{alert(res)}}
                      /*onChange={(update) => {
                        setDateRange(update);
                      }}*/
                      inline
                      monthsShown={nMonths} // number of months to display
                      className="datespicker"
                      //showDisabledMonthNavigation
                      locale='he'
                      //renderCustomHeader={(<></>)}
                  /></div>
              </DialogContent>
            </Dialog>
        )
    }
}

export default Calendar